<template>
	<div class="order-delivery">
		<SwitchLine
			class="order-delivery__type"
			:items="[
                {name: 'Доставка', value: 'delivery'},
                {name: 'Самовывоз', value: 'pickup'},
              ]"
			@itemChanged="switchDeliveryType"
			:selectedItem="currentDeliveryType"
		/>
		<div v-show="currentDeliveryType === 'delivery'">
			<div class="order-delivery__address">
				<label class="g-input">
					<span class="g-input__name">Адрес доставки:</span>
					<div class="g-input__input">
						<input
							name="name"
							type="text"
							v-model="delivery.address"
							disabled=""
						>
						<img
							src="@/assets/img/close.svg"
							alt="Close"
							@click="showAddressConfirm = true"
						>
					</div>
					<div class="g-input__sub">Обязательное поле</div>
				</label>
			</div>

			<div class="order-delivery__time-select">
				<span class="g-input__name">Время доставки:</span>
				<DateSelect
					class="order-delivery__date-select"
					@change="changeTime"
				/>
			</div>

			<div class="order-delivery__save-comment">
				<div class="order-delivery__save-comment-form">
					<label class="g-input">
						<span class="g-input__name">Комментарий для курьера:</span>
						<div class="g-input__input">
							<input
								name="name"
								type="text"
								v-model="saveComment"
								placeholder="Ваш комментарий"
							>
							<img
								src="@/assets/img/save.svg"
								class="order-delivery__save-cour"
								alt="Save"
								@click="saveCommentToUser()"
								:disabled="loading"
							/>
						</div>
					</label>
				</div>
				<div
					class="save-comment-result"
					v-if="showSaveCommentResult"
					:class="{[saveCommentResult.class]:true}"
				>
					{{ saveCommentResult.msg }}
				</div>
			</div>

		</div>

		<div v-show="currentDeliveryType === 'pickup'">
			<span class="g-input__name">Ресторан:</span>
			<OrderPickUp :restaurant="restaurant"/>
			<div class="order-delivery__time-select">
				<span class="g-input__name">Время самовывоза:</span>
				<DateSelect
					class="order-delivery__date-select"
					@change="changeTime"
				/>
			</div>
		</div>
		<div class="order-delivery__save-comment">
			<label class="g-input order-delivery__comment">
				<span class="g-input__name">Комментарий к заказу:</span>
				<textarea
					name="name"
					type="text"
					class="g-input__input order-delivery__comment-textarea"
					v-model="comment"
					placeholder="Ваш комментарий"
				/>
			</label>
		</div>

		<Popup :visible.sync="showAddressConfirm">
			<AddressAlert @allowchangeaddress="confirmChangeAddress"/>
		</Popup>
	</div>
</template>
<script>
import DateSelect from '@/components/form/DateSelect.vue';
import AddressAlert from '@/components/popup/AddressAlert.vue';
import OrderPickUp from '@/components/block/OrderPickUp';
import search from '@/mixins/search.js'
import qs from "qs";
import axios from "axios";
import {mapGetters} from "vuex";
import SwitchLine from "@/components/ui/SwitchLine.vue";

export default {
	mixins: [search],
	components: {SwitchLine, OrderPickUp, DateSelect, AddressAlert},
	data()
	{
		return {
			delivery:
				{
					address: '',
					city: '',
					time: false,
				},
			showDeliveryTime: false,
			loading: false,
			showSaveCommentResult: false,
			saveCommentResult: {class: '', msg: ''},
			showAddressConfirm: false,
		}
	},
	watch:
		{
			delivery:
				{
					handler()
					{
						this.$emit('changeFormProps', this.delivery);
					},
					deep: true
				}
		},
	computed:
		{
			...mapGetters({
				restaurant: 'order/currentRestaurant',
				currentDeliveryType: 'order/currentDeliveryType',
			}),
			saveComment:
				{
					get()
					{
						return this.$store.state.order.saveComment
					},
					set(value)
					{
						this.$store.commit('order/setSaveComment', value)
					}
				},
			comment:
				{
					get()
					{
						return this.$store.state.order.comment
					},
					set(value)
					{
						this.$store.commit('order/setComment', value)
					}
				}
		},
	methods:
		{
			switchDeliveryType(type)
			{
				this.$store.commit('order/setCurrentDeliveryType', type);
			},
			async saveCommentToUser()
			{
				this.loading = true;
				this.showSaveCommentResult = false;
				var data = qs.stringify({'comment': this.saveComment});
				var result = await axios.post('/cart/setCommentToUser/', data);

				this.showSaveCommentResult = true;
				if (result.data.success)
				{
					this.saveCommentResult.class = 'success';
					this.saveCommentResult.msg = 'Комментарий сохранён';
				} else
				{
					this.saveCommentResult.class = 'error';
					if (!result.data.authed)
					{
						this.showSaveCommentResult = false;
						if (this.saveComment === '')
							localStorage.removeItem('courierComment');
						else if (!localStorage.getItem('courierComment'))
							localStorage.setItem('courierComment', JSON.stringify(this.saveComment));
					} else
					{
						this.saveCommentResult.msg = 'Произошла ошибка, попробуйте еще раз или обновите страницу';
					}
				}
				this.loading = false;
			},
			/**
			 * Подверждение изменения адреса
			 */
			confirmChangeAddress()
			{
				this.$router.push('/map');
			},

			/**
			 * Изменение времени доставки
			 */
			changeTime(time)
			{
				this.delivery.time = time.value;
				this.changeData();
			},

			changeData()
			{
				this.$store.commit('order/setDelivery', {
					time: this.delivery.time,
				})
			}
		},
	mounted()
	{
		this.delivery.address = this.$store.state.address.address;
		this.timeIntervals = this.times;
	}
}
</script>
<style lang="scss">
.order-delivery
{
	margin-bottom: 40px;

	&__save-cour
	{
		font-size: 14px;
		color: $green;
		text-align: end;
		cursor: pointer;
	}
}

.order-delivery__type
{
	max-width: 311px;
	margin-bottom: 24px;
}

.order-delivery__title
{
	grid-column-start: 1;
	grid-column-end: 4;

	font-family: $mainFontBold;
	margin-bottom: 30px;
	font-size: 22px;
}

.order-delivery__city-change
{
	display: flex;
	align-items: center;
	gap: 10px;
}

.order-delivery__city-ctext
{
	font-size: 14px;
	line-height: 17px;
	color: #40ae49;
	cursor: pointer;
	text-decoration: underline;

	&:hover
	{
		text-decoration: none;
	}
}

.order-delivery__city-cicon
{
	color: #40ae49;
	opacity: .4;

	svg
	{
		fill: none;
	}
}

.order-delivery__time
{
	display: flex;
	padding-top: 20px;
	line-height: 100%;
}

.order-delivery__time-title
{
	font-size: 14px;
	line-height: 17px;
	color: #666;
	opacity: .7;
}

.order-delivery__time-count
{
	font-size: 18px;
	line-height: 22px;
	color: #3d4248;
}

.order-delivery__time-icon
{
	margin-right: 10px;
}

.order-delivery__address
{
	margin-bottom: 25px;
}

.order-delivery__time-select
{
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
}

.order-delivery__date-select
{max-width: 100%;}

.order-delivery__save-comment
{
	margin-bottom: 20px;

	.save-comment-result
	{
		margin-top: 5px;
		max-width: 745px;
	}

	.save-comment-result.success
	{
		color: #40AE49;
	}

	.save-comment-result.error
	{
		color: red;
	}
}

.order-delivery__comment-textarea
{
	min-height: 96px;
	resize: vertical;
}

.order-delivery__save-comment-form
{
	width: 100%;
	display: flex;
	align-items: center;

	.g-input
	{width: 100%;}
}

@media (max-width: 690px)
{
	.order-delivery__time
	{
		display: none;
	}
	.order-delivery__city .g-input
	{
		width: 100%;
		margin-right: 0px;
	}
}
</style>
